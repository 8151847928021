<template>
  <div>
    <b-alert
      :show="dataAnalysisResError"
      variant="secondary"
      class="text-center font-weight-bold"
    >
      此區間 暫無資料，請重新選擇日期或資料
    </b-alert>

    <div class="borderColor borderColorBackground mb-3">
      <!-- 頁面勾選 -->
      <div class="d-flex align-items-center flex-wrap text-center">
        <div
          v-for="select in analysisSelections"
          :key="select.order"
          class="font-weight-bold pageButton datepickerButton"
          :class="{ active: activeOrder === select.order }"
          @click="selectAnalysis(select.order)"
        >
          {{ $t(select.translation) }}
        </div>
      </div>

      <!-- 勾選 -->
      <div style="min-height: 80px">
        <!-- 若是 INV -->
        <div
          v-if="
            activeOrder !== 2 &&
            activeOrder !== 3 &&
            activeOrder !== 10 &&
            activeOrder !== 11
          "
          class="checkBoxStyle"
        >
          <b-form-checkbox
            v-model="selectedInvAll"
            value="selected"
            unchecked-value="unSelected"
            class="mr-3"
            @change="selectedToggleAll"
            >全選
          </b-form-checkbox>
          <b-form-checkbox-group
            v-model="invSelectedList"
            :options="invSelections"
          />
        </div>

        <!-- 若是 Anemometer -->
        <div
          v-if="activeOrder === 10 && anemometerSelections.length"
          class="checkBoxStyle"
        >
          <b-form-checkbox
            v-model="selectedAnemometerAll"
            value="selected"
            unchecked-value="unSelected"
            class="mr-3"
            @change="selectedToggleAll"
            >全選
          </b-form-checkbox>
          <b-form-checkbox-group
            v-model="anemometerSelectedList"
            :options="anemometerSelections"
          />
        </div>

        <button
          v-if="
            (activeOrder !== 2 && activeOrder !== 3 && activeOrder !== 11) ||
            (activeOrder === 10 && anemometerSelections.length)
          "
          type="button"
          class="confirmButton m-1 mr-3 d-flex ml-auto"
          style="width: 120px"
          @click="reSetSelectedList"
        >
          重新選擇
        </button>
      </div>

      <div
        class="d-flex align-items-center justify-content-center flex-wrap mb-3"
      >
        <div
          v-if="dateCateActive !== 0"
          class="d-flex align-items-center mr-3 mt-3"
          style="color: var(--normaltextcolor)"
        >
          <label for="checkbox" class="m-0">選擇區間&nbsp;</label>
          <b-form-checkbox
            id="checkbox"
            v-model="hasSelectedInterval"
            class="ml-1"
            @change="selectedToggleAll"
          />
        </div>

        <div v-if="!hasSelectedInterval" class="d-flex align-items-center mt-3">
          選擇日期 :&nbsp;
          <datePicker
            v-model="datepickerDate"
            class="inputStyle ml-1 mr-3"
            :format="format"
            :minimum-view="minimumView"
            :disabled-dates="disabledDates"
            :language="zh"
          />
        </div>
        <div v-else class="d-flex align-items-center mt-3">
          <datePicker
            v-model="beginDate"
            class="inputStyle"
            :format="choose"
            :minimum-view="chooseView"
            :disabled-dates="disabledDates"
            :language="zh"
          />
          <div class="mx-2">~</div>
          <datePicker
            class="inputStyle mr-3"
            v-model="endDate"
            :format="choose"
            :minimum-view="chooseView"
            :disabled-dates="disabledDates"
            :language="zh"
          />
        </div>
        <div class="mt-3">
          <!-- button margin 為使與其他頁 選擇日期區高度一致 -->
          <button
            v-if="
              hasDataAnalysisTimePermission &&
              activeOrder !== 6 &&
              activeOrder !== 10
            "
            type="button"
            class="datepickerButton"
            :class="{ 'date-active': dateCateActive === 0 }"
            style="margin: 0px 3px"
            @click="selectDateCate(0)"
          >
            {{ $t("dataAnalysis.hour") }}
          </button>
          <button
            v-if="activeOrder !== 6 && activeOrder !== 10"
            type="button"
            class="datepickerButton"
            :class="{ 'date-active': dateCateActive === 1 }"
            style="margin: 0px 3px"
            @click="selectDateCate(1)"
          >
            {{ $t("dataAnalysis.date") }}
          </button>
          <button
            v-if="activeOrder !== 6 && activeOrder !== 10"
            type="button"
            class="datepickerButton mr-3"
            :class="{ 'date-active': dateCateActive === 2 }"
            style="margin: 0px 3px"
            @click="selectDateCate(2)"
          >
            {{ $t("dataAnalysis.month") }}
          </button>
        </div>
        <button class="confirmButton mt-3" @click="buttonConfirm">
          {{ $t("deviceStatus.confirm") }}
        </button>
      </div>
    </div>

    <!-- chart -->
    <div class="borderColor borderColorBackground mb-4">
      <div class="d-flex align-items-center m-3">
        <b-form-checkbox-group
          v-model="classSelection"
          :options="multiClassSelections"
          switches
        />
        <button
          v-if="
            heatmapChart.series[0]?.data.length ||
            dataAnalysisChart.series[0]?.data.length
          "
          type="button"
          class="confirmButton ml-auto"
          style="width: 120px"
          @click="downloadAnalysis"
        >
          資料下載
        </button>
      </div>

      <div
        v-if="
          !heatmapChart.series[0]?.data.length &&
          !dataAnalysisChart.series[0]?.data.length
        "
        class="bg-white text-center"
        style="line-height: 350px"
      >
        無數據資料，請重新選擇日期或選項
      </div>

      <div v-else-if="activeOrder === 6" class="bg-white">
        <highcharts :options="heatmapChart" class="heatmapSection" />
      </div>

      <highcharts v-else :options="dataAnalysisChart" class="bg-white" />

      <!-- <div style="display: flex" class="borderColor" v-if="false">
        <div
          v-for="(i, key) in totalSumArray"
          :key="key"
          style="width: 100%; text-align: center"
        >
          <div
            v-if="
              key !== 'PR值 (%)' &&
              key !== '模組溫度 (°C)' &&
              key !== '環境溫度 (°C)'
            "
          >
            <span style="font-weight: bold; font-size: 20px">{{ key }}</span>
            <hr style="margin: 6px" />
            <div v-for="(value, y, index) in i" :key="index">
              <div v-for="(x, xvalue, xindex) in value" :key="xindex">
                <span
                  style="font-weight: bold; font-size: 18px; padding-right: 5px"
                  >{{ xvalue }} :</span
                >
                <span
                  style="font-weight: bold; font-size: 18px"
                  v-if="key !== '轉換效率 (%)'"
                  >{{ invSum(x) }}</span
                >
                <span style="font-weight: bold; font-size: 18px" v-else>{{
                  invAvg(x)
                }}</span>
              </div>
              <hr style="margin: 6px" v-if="y !== i.length - 1" />
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- table -->
    <div v-if="hasRoleAdmin && activeOrder === 11" class="borderColor mb-4">
      <b-table
        class="tableStyle"
        :items="remarkData"
        :fields="tableFields"
        hover
        small
        :responsive="true"
        foot-clone
      >
        <!-- 編輯 -->
        <template #cell(edit)="data">
          <!-- 修改/確定 -->
          <button
            type="button"
            class="btn btn-sm mr-3"
            :class="[
              remarkData[data.index].isEditing
                ? 'btn-secondary'
                : 'btn-outline-secondary',
            ]"
            @click="handleCheckRemark(data.index)"
          >
            <i v-if="!remarkData[data.index].isEditing" class="fas fa-pen"></i>
            <i v-else class="fas fa-check"></i>
          </button>

          <!-- 刪除 -->
          <button
            type="button"
            class="btn btn-outline-secondary btn-sm mr-4"
            @click="remarkData.splice(data.index, 1)"
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </template>

        <!-- 日期 -->
        <template #cell(date)="data">
          <div v-if="!remarkData?.[data.index]?.isEditing">
            {{ data.value }}
          </div>
          <div v-else class="d-flex align-items-center">
            <b-form-radio-group
              v-model="data.item.radio"
              :options="remarkDateRadioOptions"
              class="mr-3"
              buttons
              button-variant="outline-secondary"
              size="sm"
              @change="handleChangeRemarkDateType(data.index, data.item.radio)"
            />
            <vue-date-picker
              v-model="data.value"
              :type="data.item.radio"
              value-type="format"
              :clearable="false"
              class="mr-4"
              @change="
                handleSelectRemarkDate(data.index, data.field.key, data.value)
              "
            />
          </div>
        </template>

        <!-- 時間 -->
        <template #cell(time)="data">
          <div v-if="!remarkData?.[data.index]?.isEditing">
            {{ data.value }}
          </div>
          <b-form-select
            v-else
            v-model="data.value"
            class="remarkTimeSelect mr-4"
            size="sm"
            :options="remarkTimeOptions"
            :disabled="data.item.radio !== 'date'"
            @change="
              handleSelectRemarkTime(data.index, data.field.key, data.value)
            "
          />
        </template>

        <!-- 備註 -->
        <template #cell(remark)="data">
          <div v-if="!remarkData?.[data.index]?.isEditing">
            {{ data.value }}
          </div>
          <b-form-input
            v-else
            v-model="data.value"
            class="w-100 remarkInput"
            type="text"
            size="sm"
            @input="handleInputRemark(data.index, data.field.key, data.value)"
          />
        </template>

        <!-- 新增 -->
        <!-- table 要加 foot-clone ，foot 才顯示 -->
        <template #foot(edit)="data">
          <button
            type="button"
            class="btn btn-outline-info btn-sm"
            @click="
              remarkData.splice(remarkData.length, 0, {
                radio: 'date',
                date: '',
                time: null,
                remark: '',
                isEditing: true,
              })
            "
          >
            <i class="fas fa-plus" style="font-size: 16px"></i>
          </button>
        </template>
        <!-- foot 其餘欄位不顯示資訊 -->
        <template #foot()="data">{{ "" }}</template>
      </b-table>

      <button
        type="button"
        class="confirmButton ml-1 mb-1"
        @click="updateAvailabilityRemark"
      >
        更新
      </button>
    </div>
  </div>
</template>

<script>
import { BAlert, BTable, BFormInput, BFormRadioGroup } from 'bootstrap-vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import downloadCsv from 'download-csv'
import datePicker from 'vuejs-datepicker'
import { zh } from 'vuejs-datepicker/dist/locale'
import VueDatePicker from 'vue2-datepicker'
import {
  getIntervalAnalysisApi,
  getAllAnalysisDataApi,
  getAnalysisAvailabilityApi,
  getAvailabilityRemarkApi,
  updateAvailabilityRemarkApi
} from '../api'
import { dbDataHandle } from '../store/function/common/index'
import i18n from '../lang/lang'
import Highcharts from 'highcharts'
import hcHeatmap from 'highcharts/modules/heatmap'
import { isArray } from 'lodash'

hcHeatmap(Highcharts)

export default {
  name: 'DataAnalysis',
  components: {
    BAlert,
    BTable,
    BFormInput,
    BFormRadioGroup,
    VueDatePicker,
    datePicker
  },
  data () {
    return {
      zh: zh,
      hasSearchedRemark: false,
      remarkDateRadioOptions: [
        { value: 'date', text: '日' },
        { value: 'month', text: '月' }
      ],
      remarkTimeOptions: [{ value: null, text: '' }].concat(
        [...Array(24)].map((_item, idx) => {
          const option = String(idx).padStart(2, '0') + ':00'
          return { value: option, text: option }
        })
      ),
      remarkData: [
        // { radio: 'year', date: 2023, time: 02:00, remark: 'xxx', isEditing: false },
      ],
      tableFields: [
        { key: 'edit', label: '編輯' },
        { key: 'date', label: '日期' },
        { key: 'time', label: '時間' },
        { key: 'remark', label: '備註' }
      ],
      disabledDates: {
        from: new Date()
      },
      dataAnalysisResError: false,
      // totalSumArray: {},
      selectedInvAll: 'unSelected',
      selectedAnemometerAll: 'unSelected',
      changeClass: false,
      classSelection: ['發電時數 (h)', '發電量 (kWh)', '投資收入 (NTD)'],
      multiClassSelections: [],
      invStr: [],
      chartTitle: 'INVComparison',
      activeOrder: 0,
      analysisSelections: [
        {
          order: 0,
          title: '發電收入',
          translation: 'dataAnalysis.electricityRevenue'
        },
        {
          order: 1,
          title: '變流器轉換效率',
          translation: 'dataAnalysis.INVEfficiency'
        },
        {
          order: 2,
          title: '平均日照分析',
          translation: 'dataAnalysis.averageInsolation'
        },
        {
          order: 3,
          title: '溫度分析',
          translation: 'dataAnalysis.moduleTemp'
        },
        {
          order: 4,
          title: 'PR值分析',
          translation: 'dataAnalysis.PR'
        },
        {
          order: 5,
          title: '峰瓩值',
          translation: 'dataAnalysis.peakWatt'
        },
        {
          order: 6,
          title: '峰瓩值熱圖',
          translation: 'dataAnalysis.peakWattHeatmap'
        },
        {
          order: 7,
          title: '二氧化碳抑低量',
          translation: 'dataAnalysis.CO2Reduction'
        },
        {
          order: 8,
          title: 'INV溫度',
          translation: 'dataAnalysis.invTemperature'
        },
        {
          order: 9,
          title: 'Insulation',
          translation: 'dataAnalysis.insulation'
        },
        {
          order: 10,
          title: '風速計',
          translation: 'dataAnalysis.anemometer'
        },
        {
          order: 11,
          title: '妥善率',
          translation: 'dataAnalysis.availability'
        }
      ],
      dateCateActive: 1,
      format: 'yyyy-MM',
      choose: 'yyyy-MM-dd',
      invSelections: [
        'INV01',
        'INV02',
        'INV03',
        'INV04',
        'INV05',
        'INV06',
        'INV07',
        'INV08'
      ],
      invSelectedList: [],
      anemometerSelections: ['風速計01', '風速計02'],
      anemometerSelectedList: [],
      minimumView: 'day',
      chooseView: 'day',
      datepickerDate: new Date(),
      beginDate: new Date(),
      endDate: new Date(),
      hasSelectedInterval: false,
      confirmButton: true,
      awaitArray: [],
      analysisAllData: [],
      dataAnalysisChart: {
        chart: {
          height: 350,
          backgroundColor: null
        },
        credits: {
          enabled: false
        },
        title: {
          text: '發電統計與發電收入圖: 2022-03-03'
        },
        xAxis: {
          categories: []
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        yAxis: [
          {
            title: {
              text: 'sss'
            }
          },
          {
            title: {
              text: 'aaa'
            },
            opposite: true
          },
          {
            title: {
              text: 'bbbb'
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true
        },
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  y: 0
                }
              }
            }
          ]
        }
      },

      heatmapChart: {
        chart: {
          type: 'heatmap',
          // marginLeft: 300,
          // marginRight: 300,
          // marginBottom: 20,
          plotBorderWidth: 1,
          height: 380
          // spacing: [50, 50, 50, 50]
        },

        credits: {
          enabled: false
        },

        title: {
          text: '峰瓩值'
        },

        xAxis: {
          categories: [
            // 時間
          ],
          tickWidth: 1,
          tickInterval: 4
          // tickPositions: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48],
          // startOnTick: true,
          // endOnTick: true
        },

        yAxis: {
          categories: [
            // INV 台數
          ],
          title: null,
          // startOnTick: false,
          endOnTick: false
        },

        // 圖例設定
        colorAxis: {
          min: 0,
          max: 0.25,
          // tickInterval: 0.05,
          // minColor: '#FFFFFF',
          // maxColor: Highcharts.getOptions().colors[0],
          stops: [
            [0, '#1e48aa'],
            // [0.1, '#2399cc'],
            [0.25, '#2faeda'],
            // [0.3, '#99da98'],
            // [0.4, '#dcff7a'],
            [0.5, '#fcf474'],
            // [0.6, '#ffea6d'],
            [0.75, '#f77543'],
            // [0.8, '#ff8c52'],
            // [0.9, '#ff6e4e'],
            [1, '#c4463a']
          ],
          // startOnTick: true,
          // endOnTick: true,
          labels: {
            format: '{value}'
          }
        },

        // 圖例
        legend: {
          title: {
            // text: 'kW/kWp<br/><span style="font-size: 9px; color: #666; font-weight: normal">(Click to hide)</span>'
            // style: {
            //   fontStyle: 'italic'
            // }
          },
          // layout: 'vertical',
          // align: 'right',
          // margin: 20,
          // verticalAlign: 'top',
          // y: 20,
          // symbolHeight: 280
          symbolWidth: 250
        },

        tooltip: {
          formatter: function () {
            return (
              '<b>' +
              this.series.xAxis.categories[this.point.x] +
              '</b>&ensp;' +
              this.series.yAxis.categories[this.point.y] +
              '<br><b>峰瓩值: ' +
              this.point.value +
              ' (kW/kWp)</b>'
            )
          }
        },

        series: [
          {
            name: 'chart title name',
            borderWidth: 0,
            data: [
              // [x 座標, y 座標, 值]
              // [0, 0, 100],
              // [0, 1, 19]
              // [1, 0, 92]
              // [1, 1, 58],
              // [2, 0, 35],
              // [2, 1, 15],
              // [3, 0, 72],
              // [3, 1, 132],
            ]
            // dataLabels: {
            //   enabled: true,
            //   color: '#000000'
            // }
          }
        ]
      },

      // 下載的值 要存原始 raw data 的數值
      // heatMap 存的格式 [ , , ]
      // 非 heatMap 存的格式 [{ rawData: [] },{ rawData: [] }...]
      rawDataForExcel: []
    }
  },
  computed: {
    ...mapState([
      'factoryInfo',
      'factoryData',
      'capacity',
      'hasDataAnalysisTimePermission',
      'querySiteName',
      'hasRoleAdmin'
    ]),
    datepickerDateSwitcher () {
      return DateTime.fromJSDate(this.datepickerDate).toFormat(this.format)
    },
    beginDateSwitcher () {
      return DateTime.fromJSDate(this.beginDate).toFormat(this.choose)
    },
    endDateSwitcher () {
      return DateTime.fromJSDate(this.endDate).toFormat(this.choose)
    }
  },
  methods: {
    ...mapMutations(['changeDataAnalysisLang']),
    ...mapActions(['asyncLastSingleDoc']),
    handleCheckRemark (index) {
      if (
        !this.remarkData[index].date ||
        !this.remarkData[index].remark?.trim()
      ) {
        return this.$swal('請輸入日期、備註文字')
      }
      this.remarkData[index].isEditing = !this.remarkData[index].isEditing
    },
    handleChangeRemarkDateType (index, radioVal) {
      this.remarkData[index].radio = radioVal
      if (radioVal === 'date') {
        this.remarkData[index].date = DateTime.now().toFormat('yyyy-MM-dd')
      }
      if (radioVal === 'month') {
        this.remarkData[index].date = DateTime.now().toFormat('yyyy-MM')
        this.remarkData[index].time = null
      }
    },
    handleSelectRemarkDate (index, key, value) {
      this.remarkData[index][key] = value
    },
    handleSelectRemarkTime (index, key, value) {
      this.remarkData[index][key] = value
    },
    handleInputRemark (index, key, value) {
      this.remarkData[index][key] = value.trim().replace(/\s+/g, ' ') // replace 多個空格變為一個
    },
    invSum (arr) {
      return dbDataHandle(
        arr.reduce((x, y) => {
          return x + y
        })
      )
    },
    invAvg (arr) {
      return dbDataHandle(arr.reduce((x, y) => x + y, 0) / arr.length)
    },
    reSetSelectedList () {
      if (this.activeOrder === 10) {
        this.anemometerSelectedList = []
      } else {
        this.invSelectedList = []
      }
    },
    selectAnalysis (selected) {
      this.activeOrder = selected

      let dayStart = DateTime.fromJSDate(this.datepickerDate).toFormat(
        this.format
      )

      // chart title
      if (selected === 0) {
        this.chartTitle = `INVComparison`
      } else {
        this.chartTitle = `INVComparison${selected}`
      }

      // 峰瓩值熱圖、風速計 清除時間選項
      if (selected === 6 || selected === 10) {
        this.hasSelectedInterval = false
        this.dateCateActive = 0
        this.datepickerDate = new Date()
        this.format = 'yyyy-MM-dd'
        this.minimumView = 'day'
        dayStart = DateTime.fromJSDate(this.datepickerDate).toFormat(
          this.format
        )
      }

      // 若點選 風速計，則預設全選
      if (this.activeOrder === 10) {
        this.selectedAnemometerAll = 'selected'
        this.anemometerSelectedList = this.anemometerSelections
      }

      if (this.hasSelectedInterval) {
        this.confirmSearching()
      } else {
        this.awaitArrayPush(dayStart)
        this.getAllData()
      }
    },
    buttonConfirm () {
      this.changeClass = false
      this.confirmSearching()
    },
    confirmSearching () {
      if (this.hasSelectedInterval) {
        const dayStart = DateTime.fromJSDate(this.beginDate)
          .toFormat(this.choose)
          .valueOf()
        const dayEnd = DateTime.fromJSDate(this.endDate)
          .toFormat(this.choose)
          .valueOf()
        const yearStart = dayStart.slice(0, 4)
        const yearEnd = dayEnd.slice(0, 4)
        const monthStart = dayStart.slice(5, 7)
        const monthEnd = dayEnd.slice(5, 7)
        if (yearStart < yearEnd) {
          if (this.dateCateActive === 1) {
            this.awaitArray = []
            for (let j = yearStart; j <= yearEnd; j++) {
              if (j === parseInt(yearEnd)) {
                for (let i = 1; i <= monthEnd; i++) {
                  let finalDay = ''
                  if (i < 10) {
                    finalDay = `${j}-0${i}`
                  } else {
                    finalDay = `${j}-${i}`
                  }
                  this.awaitArray.push(finalDay)
                  this.getAllData()
                }
              } else {
                for (let k = parseInt(monthStart); k <= 12; k++) {
                  let finalDay = ''
                  if (k < 10) {
                    finalDay = `${yearStart}-0${k}`
                  } else {
                    finalDay = `${yearStart}-${k}`
                  }
                  this.awaitArray.push(finalDay)
                }
              }
            }
          } else {
            this.awaitArray = []
            for (let j = yearStart; j <= yearEnd; j++) {
              let finalDay = `${j}`
              this.awaitArray.push(finalDay)
              this.getAllData()
            }
          }
        } else {
          if (this.dateCateActive === 1) {
            for (let i = parseInt(monthStart); i <= monthEnd; i++) {
              let firstTime = false
              if (i === parseInt(monthStart)) {
                firstTime = true
              }
              let finalDay = ''
              if (i < 10) {
                finalDay = `${yearStart}-0${i}`
              } else {
                finalDay = `${yearStart}-${i}`
              }
              this.awaitArrayPush(finalDay, firstTime, true)
            }
            this.getAllData()
          } else {
            this.awaitArrayPush(yearStart)
            this.getAllData()
          }
        }
      } else {
        const dayStart = DateTime.fromJSDate(this.datepickerDate).toFormat(
          this.format
        )
        this.awaitArrayPush(dayStart)
        this.getAllData()
      }
    },
    selectDateCate (selected) {
      this.dateCateActive = selected
      if (this.dateCateActive === 0) {
        this.format = 'yyyy-MM-dd'
        this.minimumView = 'day'
        this.choose = 'yyyy-MM-dd'
        this.chooseView = 'day'
        this.hasSelectedInterval = false
      } else if (this.dateCateActive === 1) {
        this.format = 'yyyy-MM'
        this.minimumView = 'month'
        this.choose = 'yyyy-MM-dd'
        this.chooseView = 'day'
      } else if (this.dateCateActive === 2) {
        this.format = 'yyyy'
        this.minimumView = 'year'
        this.choose = 'yyyy-MM'
        this.chooseView = 'month'
      }

      // PR 值分析 切換 時/日/月，於 日/月 時 toggle 選項重整
      if (this.activeOrder === 4 && this.dateCateActive !== 0) {
        this.changeClass = true
      } else {
        this.changeClass = false
      }
      this.confirmSearching()
    },
    downloadAnalysis () {
      const downloadArr = []
      let fields = ['name']

      const isHeatMap = this.activeOrder === 6

      if (isHeatMap) {
        const selectedInvArr = [
          ...this.heatmapChart.yAxis.categories
        ].reverse()

        // inv (excel column title)
        fields = fields.concat(selectedInvArr)

        // time (excel row title)
        this.heatmapChart.xAxis.categories.forEach((time) => {
          downloadArr.push({ name: time })
        })

        // 全選 (直接取用做好的圖表數據)
        if (fields[1] === 'average') {
          this.heatmapChart.yAxis.categories.forEach((inv) => {
            downloadArr.forEach((item, idx) => {
              item[`${inv}`] = this.rawDataForExcel[idx]
            })
          })
        }

        // 有選擇 INV (從 db 拿的資料處理)
        if (fields[1] !== 'average') {
          downloadArr.forEach((rowItem, rowIdx) => {
            // 06:00 -> foundTimeData (hhmm: 06:00)
            // 06:15 -> foundTimeData (hhmm: 06:00)
            // 07:45 -> foundTimeData (hhmm: 07:00)
            const foundTimeData = this.analysisAllData.filter(
              (data) => data.hhmm.split(':')[0] === rowItem.name.split(':')[0]
            )

            if (foundTimeData.length) {
              // 'INV02', 'INV08', 'INV11'...
              selectedInvArr.forEach((invName) => {
                const invIndex = Number(invName.slice(3)) // 2, 8, 11...
                const insertData =
                  foundTimeData[0].kWp[rowIdx % 4][invIndex - 1]
                // rowIdx 0 | rowItem 06:00 | kWp index 0
                // rowIdx 1 | rowItem 06:15 | kWp index 1
                // rowIdx 2 | rowItem 06:30 | kWp index 2
                // rowIdx 3 | rowItem 06:45 | kWp index 3
                // rowIdx 4 | rowItem 07:00 | kWp index 0
                // rowIdx 5 | rowItem 07:00 | kWp index 1
                // rowIdx % 4 為 kWp index

                rowItem[`${invName}`] = insertData ?? null
              })
            }
          })
        }
      }

      if (!isHeatMap && this.activeOrder !== 11) {
        fields = fields.concat(this.dataAnalysisChart.xAxis.categories)
        this.dataAnalysisChart.series.forEach((x) => {
          downloadArr.push({ name: x.name })
        })
        this.dataAnalysisChart.xAxis.categories.forEach((x, xIndex) => {
          downloadArr.forEach((y, yIndex) => {
            y[`${x}`] = this.rawDataForExcel[yIndex].rawData[xIndex]
          })
        })
      }

      if (!isHeatMap && this.activeOrder === 11) {
        // 妥善率
        fields = fields.concat(this.dataAnalysisChart.xAxis.categories) // ['name'] + ['06:00','07:00'...]
        this.dataAnalysisChart.series.forEach((x) => {
          downloadArr.push({ name: x.name })
        })
        this.dataAnalysisChart.xAxis.categories.forEach((time, timeIndex) => {
          downloadArr.forEach((y, yIndex) => {
            y[`${time}`] = this.rawDataForExcel[yIndex].rawData[timeIndex]
          })
        })

        // 妥善率的 tooltip 備註，也要放入資料下載
        fields.forEach((key, index) => {
          if (index === 0) return downloadArr.push({ name: '備註' })
          downloadArr[downloadArr.length - 1][key] =
            this.dataAnalysisChart.series[0].data[index - 1].remark
        })
      }

      const fileNameArr = [
        '發電收入',
        '變流器轉換效率',
        '平均日照分析',
        '溫度分析',
        'PR值分析',
        '峰瓩值',
        '峰瓩值熱圖',
        '二氧化碳抑低量',
        'INV溫度',
        'Insulation',
        '風速計',
        '妥善率'
      ]
      const fileName = `資料分析_${fileNameArr[this.activeOrder]}`

      downloadCsv(downloadArr, fields, fileName)
    },
    awaitArrayPush (date, firstTime, multiDate) {
      if (multiDate) {
        if (firstTime) {
          this.awaitArray = []
          this.awaitArray.push(date)
        } else {
          this.awaitArray.push(date)
        }
      } else {
        this.awaitArray = []
        this.awaitArray.push(date)
      }
    },
    async getAvailabilityRemark () {
      try {
        const fId = this.$route.query.fid
        const { data } = await getAvailabilityRemarkApi(fId)
        if (data?.data?.length && data.data?.[0]?.length) {
          // 有可能是 [[]]
          this.remarkData = data.data.map((arr) => ({
            radio: arr[0].length > 7 ? 'date' : 'month',
            date: arr[0].split(' ')?.[0],
            time: arr[0].split(' ')?.[1] ?? null,
            remark: arr[1],
            isEditing: false
          }))
        }
      } catch (error) {
        console.log('getAvailabilityRemark', error)
      } finally {
        this.hasSearchedRemark = true
      }
    },
    async updateAvailabilityRemark () {
      // check
      const hasEdit = this.remarkData
        .map((obj) => obj.isEditing)
        .includes(true)
      if (hasEdit) return this.$swal('請先確定所有編輯中的欄位')

      try {
        const fId = this.$route.query.fid
        let remarks = this.remarkData
          .sort((a, b) => {
            const aTime = a.time
              ? new Date(`${a.date} ${a.time}`)
              : new Date(`${a.date} 23:59`)
            const bTime = b.time
              ? new Date(`${b.date} ${b.time}`)
              : new Date(`${b.date} 23:59`)
            return bTime - aTime
          })
          .map((obj) => {
            if (!obj.time) return [obj.date, obj.remark]
            return [`${obj.date} ${obj.time}`, obj.remark]
          })

        if (!remarks.length) remarks = [[]] // 無資料時，這樣才能存進 db

        const { data } = await updateAvailabilityRemarkApi(fId, remarks)
        if (data?.success) {
          await this.getAllData()
          await this.getAvailabilityRemark()
          this.$swal('更新資料成功')
        }
      } catch (error) {
        console.log('updateAvailabilityRemark', error)
      }
    },
    async getAllData () {
      const fId = this.$route.query.fid
      let commitArray = []

      const isInterval = this.activeOrder === 6 || this.activeOrder === 10
      const isAvailability = this.activeOrder === 11

      if (isInterval) {
        const getIntervalAnalysis = await getIntervalAnalysisApi(
          fId,
          this.awaitArray[0]
        )
        if (getIntervalAnalysis?.data?.success) {
          this.dataAnalysisResError = false
          getIntervalAnalysis.data.data.forEach((x) => commitArray.push(x))
        } else {
          this.dataAnalysisResError = true
        }
        this.analysisAllData = commitArray
      }

      if (isAvailability) {
        for (let i = 0; i < this.awaitArray.length; i++) {
          const getAvailabilityData = await getAnalysisAvailabilityApi(
            this.querySiteName,
            fId,
            this.awaitArray[i]
          )
          if (getAvailabilityData?.data?.success) {
            this.dataAnalysisResError = false
            getAvailabilityData.data.data.forEach((x) => commitArray.push(x))
          } else {
            this.dataAnalysisResError = true
          }
          this.analysisAllData = commitArray
        }
      }

      if (!isInterval && !isAvailability) {
        for (let i = 0; i < this.awaitArray.length; i++) {
          const getAllAnalysisData = await getAllAnalysisDataApi(
            this.querySiteName,
            fId,
            this.awaitArray[i]
          )
          if (getAllAnalysisData?.data?.success) {
            this.dataAnalysisResError = false
            getAllAnalysisData.data.data.forEach((x) => commitArray.push(x))
          } else {
            this.dataAnalysisResError = true
          }
          this.analysisAllData = commitArray
        }
      }

      this.analysisAllDataPrepare()
    },
    analysisAllDataPrepare (isChangeClass) {
      const beginDate = DateTime.fromJSDate(this.beginDate)
        .toFormat(this.choose)
        .valueOf()
      const endDate = DateTime.fromJSDate(this.endDate)
        .toFormat(this.choose)
        .valueOf()
      let invArr =
        this.invSelectedList.length > 0
          ? this.invSelectedList
            .map((x) => Number(x.slice(-2)))
            .sort((a, b) => a - b)
          : ['ALL']

      if (
        this.activeOrder === 2 ||
        this.activeOrder === 3 ||
        this.activeOrder === 11
      ) {
        invArr = ['ALL']
      }

      if (this.activeOrder === 10) {
        invArr = this.anemometerSelectedList
          .map((item) => Number(item.slice(-2)))
          .sort((a, b) => a - b)
      }

      this.rawDataForExcel = []

      this.dataAnalysisChart.series = []
      this.dataAnalysisChart.xAxis.categories = []

      this.heatmapChart.series[0].data = []
      this.heatmapChart.xAxis.categories = []

      this.invStr = []

      invArr.forEach((x) => this.invStr.push(x))
      if (this.hasSelectedInterval) {
        this.analysisAllData = this.analysisAllData.filter(
          (x) => x.hhmm >= beginDate && x.hhmm <= endDate
        )
      }
      this.dataAnalysisChart.title.text = this.hasSelectedInterval
        ? i18n.t(`dataAnalysis.${this.chartTitle}`) +
          ': ' +
          this.beginDateSwitcher +
          ' ~ ' +
          this.endDateSwitcher
        : i18n.t(`dataAnalysis.${this.chartTitle}`) +
          ': ' +
          this.datepickerDateSwitcher

      this.heatmapChart.title.text =
        i18n.t(`dataAnalysis.${this.chartTitle}`) +
        ': ' +
        this.datepickerDateSwitcher
      if (this.activeOrder === 0) this.getElectricByDays(invArr, isChangeClass)
      if (this.activeOrder === 1) this.getInvTransform(invArr, isChangeClass)
      if (this.activeOrder === 2) this.getAverageIrr(invArr, isChangeClass)
      if (this.activeOrder === 3) this.getTemp(invArr, isChangeClass)
      if (this.activeOrder === 4) this.getPR(invArr, isChangeClass)
      if (this.activeOrder === 5) this.getPeakWatt(invArr, isChangeClass)
      if (this.activeOrder === 6) {
        this.getPeakWattHeatmap(invArr, isChangeClass)
      }
      if (this.activeOrder === 7) this.getCo2(invArr, isChangeClass)
      if (this.activeOrder === 8) this.getInvTemp(invArr, isChangeClass)
      if (this.activeOrder === 9) this.getInsulation(invArr, isChangeClass)
      if (this.activeOrder === 10) this.getAnemometer(invArr, isChangeClass)
      if (this.activeOrder === 11) this.getAvailability(invArr, isChangeClass)
    },
    dataAnalysisChartSeriesPrepare (name, invArr) {
      // this.totalSumArray = {}
      for (let i = 0; i < name.length; i++) {
        // this.totalSumArray[name[i]] = [{}]
        for (let j = 0; j < invArr.length; j++) {
          const yIndex =
            name.includes('模組溫度 (°C)') || name.includes('環境溫度 (°C)')
              ? 0
              : i
          this.dataAnalysisChart.series.push({
            type: 'spline',
            yAxis: yIndex,
            name:
              this.activeOrder === 10
                ? `Anemometer${invArr[j]}-${name[i]}`
                : `Inv${invArr[j]}-${name[i]}`,
            data: []
          })

          this.rawDataForExcel.push({ rawData: [] })
          // this.totalSumArray[name[i]][j] = {}
          // this.totalSumArray[name[i]][j][`Inv${invArr[j]}`] = []
        }
      }
      this.dataAnalysisChart.yAxis = []

      if (name.includes('模組溫度 (°C)') || name.includes('環境溫度 (°C)')) {
        this.dataAnalysisChart.yAxis = [
          {
            title: {
              text: '溫度 (°C)'
            },
            opposite: false
          }
        ]
      } else {
        name.forEach((x, idx) => {
          this.dataAnalysisChart.yAxis.push({
            title: {
              text: x
            },
            opposite: idx > 0 ? 1 : false
          })
        })
      }
    },
    getElectricByDays (invArr, isChangeClass) {
      this.multiClassSelections = [
        '發電時數 (h)',
        '發電量 (kWh)',
        '投資收入 (NTD)'
      ]
      if (!isChangeClass && this.changeClass) {
        this.classSelection = [
          '發電時數 (h)',
          '發電量 (kWh)',
          '投資收入 (NTD)'
        ]
      }
      const name = this.classSelection
      this.dataAnalysisChartSeriesPrepare(name, invArr)
      this.analysisAllData.forEach((item) => {
        if (item.acp?.all) {
          this.dataAnalysisChart.xAxis.categories.push(item.hhmm)
          for (let k = 0; k < name.length; k++) {
            for (let x = 0; x < invArr.length; x++) {
              let index = x + k * invArr.length
              if (name[k] === '發電量 (kWh)') {
                const type =
                  this.invSelectedList.length === 0
                    ? item.acp.all
                    : item.acp.invs[invArr[x] - 1]
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(type)
                )

                this.rawDataForExcel[index].rawData.push(type)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(type)
              } else if (name[k] === '發電時數 (h)') {
                const type =
                  this.invSelectedList.length === 0
                    ? item.acp.all / this.capacity.reduce((a, b) => a + b)
                    : item.acp.invs[invArr[x] - 1] /
                      this.capacity[invArr[x] - 1]
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(type)
                )

                this.rawDataForExcel[index].rawData.push(type)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(type)
              } else if (name[k] === '投資收入 (NTD)') {
                const type =
                  this.invSelectedList.length === 0
                    ? item.acp.all
                    : item.acp.invs[invArr[x] - 1]
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(type * 4.96)
                )

                this.rawDataForExcel[index].rawData.push(type * 4.96)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(
                //   type * 4.96
                // )
              }
            }
          }
        }
      })
    },
    getInvTransform (invArr, isChangeClass) {
      this.multiClassSelections = ['轉換效率 (%)']
      if (!isChangeClass && this.changeClass) {
        this.classSelection = ['轉換效率 (%)']
      }
      const name = this.classSelection
      this.dataAnalysisChartSeriesPrepare(name, invArr)
      this.analysisAllData.forEach((item) => {
        if (item.Eff?.all) {
          this.dataAnalysisChart.xAxis.categories.push(item.hhmm)
          for (let k = 0; k < name.length; k++) {
            for (let x = 0; x < invArr.length; x++) {
              let index = x + k * invArr.length
              if (name[k] === '轉換效率 (%)') {
                if (item.Eff) {
                  const type =
                    this.invSelectedList.length === 0
                      ? item.Eff.all
                      : item.Eff.invs[invArr[x] - 1]
                  this.dataAnalysisChart.series[index].data.push(
                    dbDataHandle(type * 100)
                  )

                  this.rawDataForExcel[index].rawData.push(type * 100)
                  // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(
                  //   type * 100
                  // )
                }
              }
            }
          }
        }
      })
    },
    getAverageIrr (invArr, isChangeClass) {
      this.multiClassSelections = ['平均日照 (kWh/㎡)']
      if (!isChangeClass && this.changeClass) {
        this.classSelection = ['平均日照 (kWh/㎡)']
      }
      const name = this.classSelection
      this.dataAnalysisChartSeriesPrepare(name, invArr)
      this.analysisAllData.forEach((item) => {
        if (item.IRR) {
          this.dataAnalysisChart.xAxis.categories.push(item.hhmm)
          for (let k = 0; k < name.length; k++) {
            for (let x = 0; x < invArr.length; x++) {
              let index = x + k * invArr.length
              if (name[k] === '平均日照 (kWh/㎡)') {
                const irrAll =
                  typeof item.IRR === 'object' ? item.IRR.avg : item.IRR
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(irrAll)
                )

                this.rawDataForExcel[index].rawData.push(irrAll)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(irrAll)
              }
            }
          }
        }
      })
    },
    getTemp (invArr, isChangeClass) {
      this.multiClassSelections = ['模組溫度 (°C)', '環境溫度 (°C)']
      if (!isChangeClass && this.changeClass) {
        this.classSelection = ['模組溫度 (°C)', '環境溫度 (°C)']
      }
      const name = this.classSelection
      this.dataAnalysisChartSeriesPrepare(name, invArr)
      this.analysisAllData.forEach((item) => {
        if (item.PVTemp || item.ENVTemp) {
          this.dataAnalysisChart.xAxis.categories.push(item.hhmm)
          for (let k = 0; k < name.length; k++) {
            for (let x = 0; x < invArr.length; x++) {
              let index = x + k * invArr.length
              if (name[k] === '模組溫度 (°C)') {
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(item.PVTemp)
                )

                this.rawDataForExcel[index].rawData.push(item.PVTemp)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(
                //   item.PVTemp
                // )
              } else if (name[k] === '環境溫度 (°C)') {
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(item.ENVTemp)
                )

                this.rawDataForExcel[index].rawData.push(item.ENVTemp)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(
                //   item.ENVTemp
                // )
              }
            }
          }
        }
      })
    },
    getPR (invArr, isChangeClass) {
      this.multiClassSelections =
        this.dateCateActive === 0
          ? ['等效日照時數 (h)', '發電時數 (h)']
          : ['等效日照時數 (h)', '發電時數 (h)', 'PR值 (%)']
      if (!isChangeClass && this.changeClass) {
        this.classSelection =
          this.dateCateActive === 0
            ? ['等效日照時數 (h)', '發電時數 (h)']
            : ['等效日照時數 (h)', '發電時數 (h)', 'PR值 (%)']
      }
      const name = this.classSelection
      this.dataAnalysisChartSeriesPrepare(name, invArr)
      this.analysisAllData.forEach((item) => {
        if (item.acp?.all) {
          const IRRdata =
            item.IRR && typeof item.IRR === 'object' ? item.IRR.avg : item.IRR
          this.dataAnalysisChart.xAxis.categories.push(item.hhmm)
          for (let k = 0; k < name.length; k++) {
            for (let x = 0; x < invArr.length; x++) {
              let index = x + k * invArr.length
              if (name[k] === '等效日照時數 (h)' && item.IRR) {
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(IRRdata)
                )

                this.rawDataForExcel[index].rawData.push(IRRdata)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(IRRdata)
              } else if (name[k] === '發電時數 (h)') {
                const type =
                  this.invSelectedList.length === 0
                    ? item.acp.all / this.capacity.reduce((a, b) => a + b)
                    : item.acp.invs[invArr[x] - 1] /
                      this.capacity[invArr[x] - 1]
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(type)
                )

                this.rawDataForExcel[index].rawData.push(type)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(type)
              } else if (
                name[k] === 'PR值 (%)' &&
                this.dateCateActive !== 0 &&
                item.pr
              ) {
                // let PR = this.invSelectedList.length === 0 ? calculatePR(this.capacity, item.acp.all, IRRdata) : dbDataHandle((item.acp.invs[invArr[x] - 1] / this.capacity[invArr[x] - 1] / IRRdata) * 100)
                let PR =
                  this.invSelectedList.length === 0
                    ? item.pr.all * 100
                    : item.pr.invs[invArr[x] - 1] * 100
                if (PR >= 100) PR = 100
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(PR, 1)
                )

                this.rawDataForExcel[index].rawData.push(PR)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(PR)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(PR)
              }
            }
          }
        }
      })
    },
    getPeakWatt (invArr, isChangeClass) {
      this.multiClassSelections = ['峰瓩值 (kWh/kWp)', '發電量 (kWh)']
      if (!isChangeClass && this.changeClass) {
        this.classSelection = ['峰瓩值 (kWh/kWp)', '發電量 (kWh)']
      }
      const name = this.classSelection
      this.dataAnalysisChartSeriesPrepare(name, invArr)
      this.analysisAllData.forEach((item) => {
        if (item.acp?.all) {
          this.dataAnalysisChart.xAxis.categories.push(item.hhmm)
          for (let k = 0; k < name.length; k++) {
            for (let x = 0; x < invArr.length; x++) {
              let index = x + k * invArr.length
              if (name[k] === '峰瓩值 (kWh/kWp)') {
                const PeakWatt =
                  this.invSelectedList.length === 0
                    ? item.acp.all / this.capacity.reduce((a, b) => a + b)
                    : item.acp.invs[invArr[x] - 1] /
                      this.capacity[invArr[x] - 1]

                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(PeakWatt)
                )

                this.rawDataForExcel[index].rawData.push(PeakWatt)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(
                //   PeakWatt
                // )
              } else if (name[k] === '發電量 (kWh)') {
                const acp =
                  this.invSelectedList.length === 0
                    ? item.acp.all
                    : item.acp.invs[invArr[x] - 1]
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(acp)
                )

                this.rawDataForExcel[index].rawData.push(acp)
              }
            }
          }
        }
      })
    },
    getPeakWattHeatmap (invArr, isChangeClass) {
      this.multiClassSelections = ['峰瓩值 (kW/kWp)']
      if (!isChangeClass && this.changeClass) {
        this.classSelection = ['峰瓩值 (kW/kWp)']
      }
      const name = this.classSelection

      this.heatmapChart.yAxis.categories = []
      this.heatmapChart.yAxis.categories =
        invArr[0] === 'ALL'
          ? ['average']
          : invArr
            .sort((a, b) => b - a)
            .map((invNum) =>
              (invNum + '').length === 1 ? `INV0${invNum}` : `INV${invNum}`
            )

      // 取 06:00 - 17:45
      for (let xAxisTime = 6; xAxisTime <= 17; xAxisTime++) {
        let pushTimeArr =
          (xAxisTime + '').length === 1
            ? [
              `0${xAxisTime}:00`,
              `0${xAxisTime}:15`,
              `0${xAxisTime}:30`,
              `0${xAxisTime}:45`
            ]
            : [
              `${xAxisTime}:00`,
              `${xAxisTime}:15`,
              `${xAxisTime}:30`,
              `${xAxisTime}:45`
            ]
        this.heatmapChart.xAxis.categories.push(...pushTimeArr)
      }

      this.heatmapChart.xAxis.categories.forEach((time, xAxisIndex) => {
        const foundTimeIndex = this.analysisAllData.findIndex(
          (item) => item.hhmm === time
        )

        if (foundTimeIndex !== -1) {
          const foundTimeData = this.analysisAllData[foundTimeIndex]

          // classSelection
          for (let k = 0; k < name.length; k++) {
            // select inv 台數
            for (let x = 0; x < invArr.length; x++) {
              // 時間區間 00 15 30 45
              for (let period = 0; period < 4; period++) {
                const PeakWatt = foundTimeData.kWp[period]
                  ? this.invSelectedList.length === 0
                    ? foundTimeData.kWp[period].reduce((a, b) => a + b) /
                      foundTimeData.kWp[period].length
                    : foundTimeData.kWp[period][invArr[x] - 1]
                  : null
                this.heatmapChart.series[0].data.push([
                  xAxisIndex + period, // x 座標
                  x, // y 座標(inv)
                  PeakWatt || PeakWatt === 0
                    ? Math.round(PeakWatt * 100) / 100
                    : null // 數值
                ])

                this.rawDataForExcel.push(PeakWatt)
              }
            }
          }
        }

        if (foundTimeIndex === -1 && time.split(':')[1] === '00') {
          for (let k = 0; k < name.length; k++) {
            // select inv 台數
            for (let x = 0; x < invArr.length; x++) {
              for (let period = 0; period < 4; period++) {
                this.heatmapChart.series[0].data.push([
                  xAxisIndex + period,
                  x,
                  null
                ])

                this.rawDataForExcel.push(null)
              }
            }
          }
        }
      })
    },
    getCo2 (invArr, isChangeClass) {
      this.multiClassSelections = ['二氧化碳排放抑制量 (kg)', '種樹 (棵)']
      if (!isChangeClass && this.changeClass) {
        this.classSelection = ['二氧化碳排放抑制量 (kg)', '種樹 (棵)']
      }
      const name = this.classSelection
      this.dataAnalysisChartSeriesPrepare(name, invArr)
      this.analysisAllData.forEach((item) => {
        if (item.acp?.all) {
          this.dataAnalysisChart.xAxis.categories.push(item.hhmm)
          for (let k = 0; k < name.length; k++) {
            for (let x = 0; x < invArr.length; x++) {
              let index = x + k * invArr.length
              if (name[k] === '二氧化碳排放抑制量 (kg)') {
                const co2 =
                  this.invSelectedList.length === 0
                    ? item.acp.all
                    : item.acp.invs[invArr[x] - 1]
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(co2 * 0.495)
                )

                this.rawDataForExcel[index].rawData.push(co2 * 0.495)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(co2)
              } else if (name[k] === '種樹 (棵)') {
                const tree =
                  this.invSelectedList.length === 0
                    ? item.acp.all
                    : item.acp.invs[invArr[x] - 1]
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(tree * 20)
                )

                this.rawDataForExcel[index].rawData.push(tree * 20)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(tree)
              }
            }
          }
        }
      })
    },
    getInvTemp (invArr, isChangeClass) {
      this.multiClassSelections = ['變流器溫度 (°C)']
      if (!isChangeClass && this.changeClass) {
        this.classSelection = ['變流器溫度 (°C)']
      }
      const name = this.classSelection
      this.dataAnalysisChartSeriesPrepare(name, invArr)
      this.analysisAllData.forEach((item) => {
        if (item.temp) {
          this.dataAnalysisChart.xAxis.categories.push(item.hhmm)
          for (let k = 0; k < name.length; k++) {
            for (let x = 0; x < invArr.length; x++) {
              let index = x + k * invArr.length
              if (name[k] === '變流器溫度 (°C)') {
                const type =
                  this.invSelectedList.length === 0
                    ? item.temp.avg
                      ? item.temp.avg
                      : item.temp
                    : item.temp.invs[invArr[x] - 1]
                      ? item.temp.invs[invArr[x] - 1]
                      : item.temp
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(type)
                )

                this.rawDataForExcel[index].rawData.push(type)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(type)
              }
            }
          }
        }
      })
    },
    getInsulation (invArr, isChangeClass) {
      this.multiClassSelections = ['平均絕緣阻抗值(MΩ)']
      if (!isChangeClass && this.changeClass) {
        this.classSelection = ['平均絕緣阻抗值(MΩ)']
      }
      const name = this.classSelection
      this.dataAnalysisChartSeriesPrepare(name, invArr)
      this.analysisAllData.forEach((item) => {
        if (item.Insulation) {
          this.dataAnalysisChart.xAxis.categories.push(item.hhmm)
          for (let k = 0; k < name.length; k++) {
            for (let x = 0; x < invArr.length; x++) {
              let index = x + k * invArr.length
              if (name[k] === '平均絕緣阻抗值(MΩ)') {
                const type =
                  this.invSelectedList.length === 0
                    ? item.Insulation.avg
                    : item.Insulation.invs[invArr[x] - 1]
                this.dataAnalysisChart.series[index].data.push(
                  dbDataHandle(type)
                )

                this.rawDataForExcel[index].rawData.push(type)
                // this.totalSumArray[name[k]][x][`Inv${invArr[x]}`].push(type)
              }
            }
          }
        }
      })
    },
    getAnemometer (invArr, isChangeClass) {
      this.multiClassSelections = ['平均風速 (m/s)', '瞬間最大陣風 (m/s)']
      if (!isChangeClass && this.changeClass) {
        this.classSelection = ['平均風速 (m/s)', '瞬間最大陣風 (m/s)']
      }
      const name = this.classSelection
      this.dataAnalysisChartSeriesPrepare(name, invArr)
      this.analysisAllData.forEach((item) => {
        if (item.anemometer?.avg) {
          this.dataAnalysisChart.xAxis.categories.push(item.hhmm)
          for (let k = 0; k < name.length; k++) {
            for (let x = 0; x < invArr.length; x++) {
              let index = x + k * invArr.length
              if (name[k] === '平均風速 (m/s)') {
                // 若不是 Array ，其值為 Anemometer1，其他風速計為 null
                const avg = isArray(item.anemometer?.avg)
                  ? item.anemometer?.avg?.[invArr[x] - 1]
                  : invArr[x] === 1
                    ? item.anemometer?.avg
                    : null

                this.dataAnalysisChart.series[index].data.push(
                  avg ? Math.round(avg * 100) / 100 : null
                )

                this.rawDataForExcel[index].rawData.push(avg)
              } else if (name[k] === '瞬間最大陣風 (m/s)') {
                const max = isArray(item.anemometer?.max)
                  ? item.anemometer?.max?.[invArr[x] - 1]
                  : invArr[x] === 1
                    ? item.anemometer?.max
                    : null
                this.dataAnalysisChart.series[index].data.push(
                  max ? Math.round(max * 100) / 100 : null
                )

                this.rawDataForExcel[index].rawData.push(max)
              }
            }
          }
        }
      })

      // yAxis max
      if (this.dataAnalysisChart.series[0]?.data.length) {
        name.forEach((_, idx) => {
          // 各 data 的 max 中取出唯一 max
          this.dataAnalysisChart.yAxis[idx].max = Math.max(
            ...this.dataAnalysisChart.series.map((obj) => Math.max(...obj.data))
          )
          this.dataAnalysisChart.yAxis[idx].min = 0
        })
      }
    },
    getAvailability (invArr, isChangeClass) {
      this.multiClassSelections = ['妥善率 (%)']
      if (!isChangeClass && this.changeClass) {
        this.classSelection = ['妥善率 (%)']
      }
      const name = this.classSelection
      this.dataAnalysisChartSeriesPrepare(name, invArr)
      this.analysisAllData.forEach((item) => {
        if (item.availabilityVal) {
          this.dataAnalysisChart.xAxis.categories.push(item.hhmm)
          for (let k = 0; k < name.length; k++) {
            for (let x = 0; x < invArr.length; x++) {
              let index = x + k * invArr.length
              if (name[k] === '妥善率 (%)') {
                // series data
                this.dataAnalysisChart.series[index].data.push({
                  y: dbDataHandle(item.availabilityVal),
                  remark: item.remark // 客製 自行放入資料 (data 改用 [{}] 設置)
                })

                // tooltip
                this.dataAnalysisChart.tooltip = {
                  formatter: function () {
                    // console.log(this.point)
                    const isRemark = this.point.remark // 取用自行放入的資料
                      ? `<br/>備註:<br/>${this.point.remark}`
                      : ''

                    return (
                      `<span style="font-size: 0.8em">${this.point.category}</span><br/>` +
                      `<span style="color:${this.point.color}">\u25CF</span> ` +
                      `${this.series.name}: <b>${this.point.y}</b><br/>` +
                      isRemark
                    )
                  }
                }

                // excel
                this.rawDataForExcel[index].rawData.push(item.availabilityVal)
              }
            }
          }
        }
      })
    },
    selectedToggleAll () {
      if (this.activeOrder === 10) {
        this.anemometerSelectedList =
          this.selectedAnemometerAll === 'selected'
            ? this.anemometerSelections
            : []
      } else {
        this.invSelectedList =
          this.selectedInvAll === 'selected' ? this.invSelections : []
      }
    },
    invSelectedListPrepare () {
      this.invSelections = []
      for (let i = 1; i < this.$route.query.invLen + 1; i++) {
        const num = `INV${('0' + i).slice(-2)}`
        if (i <= this.$route.query.invLen) {
          this.invSelections.push(num)
        }
      }
    },
    anemometerSelectedListPrepare () {
      this.anemometerSelections = []
      for (let i = 1; i < this.$route.query.anemometerLen + 1; i++) {
        const num = `風速計${('0' + i).slice(-2)}`
        if (i <= this.$route.query.anemometerLen) {
          this.anemometerSelections.push(num)
        }
      }
    }
  },
  watch: {
    invSelectedList: function (newVal, preVal) {
      this.selectedInvAll =
        this.invSelectedList.length === this.invSelections.length
          ? 'selected'
          : 'unSelected'
    },
    anemometerSelectedList: function (newVal, preVal) {
      this.selectedAnemometerAll =
        this.anemometerSelectedList.length === this.anemometerSelections.length
          ? 'selected'
          : 'unSelected'
    },
    classSelection: function (newVal, preVal) {
      if (newVal !== preVal) {
        this.analysisAllDataPrepare(true)
      }
    },
    activeOrder: function (newVal, preVal) {
      // 使用 tooltip formatter 不能使用 shared，點選其他頁面時更改回來
      if (newVal !== 11) this.dataAnalysisChart.tooltip = { shared: true }
      if (newVal === 11 && !this.remarkData?.length) {
        this.getAvailabilityRemark()
        this.hasSearchedRemark = true
      }

      this.changeClass = true
    }
  },
  async created () {
    if (this.hasDataAnalysisTimePermission) {
      this.dateCateActive = 0
      this.format = 'yyyy-MM-dd'
    }

    if (!this.factoryData.length) {
      await this.asyncLastSingleDoc(this.$route.query.siteName)
    }
    const dayStart = DateTime.fromJSDate(this.datepickerDate).toFormat(
      this.format
    )
    this.awaitArrayPush(dayStart)
    this.invSelectedListPrepare()
    this.anemometerSelectedListPrepare()
    this.getAllData()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/customVariables.scss";

// cover bootstrap css
.btn {
  &:focus {
    box-shadow: none;
  }
}

// cover css
:deep(.tableStyle) {
  .table {
    box-shadow: none;
    th {
      text-align: left;
      &:nth-child(1) {
        width: 8%;
      }
      &:nth-child(2) {
        width: 12.5%;
      }
      &:nth-child(3) {
        width: 10%;
      }
    }
    td {
      text-align: left;
      line-height: 32px;
    }
    tfoot {
      tr {
        th {
          background-color: #fff;
        }
      }
    }
  }
}

.remarkTimeSelect {
  font-size: $fz-md;
  width: 100px;
  &:focus {
    // cover bootstraps css
    border-color: $c-light-blue;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

input {
  &.remarkInput {
    font-size: $fz-md;
    font-weight: normal;
    min-width: 250px;
    &:focus {
      // cover bootstraps css
      border-color: $c-light-blue;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  &.remarkTimeSelect {
    font-size: $fz-md;
  }
}

:deep(input) {
  &.mx-input {
    font-weight: normal;
    font-size: 16px;
  }
}

.checkBoxStyle {
  display: flex;
  max-height: 110px;
  overflow: auto;
  padding: 5px;
}

.pageButton {
  min-width: 140px;

  &:hover {
    background-color: rgba(114, 114, 114, 0.596);
  }

  &.active {
    color: white;
    background: linear-gradient(to top, $acme-blue-3, $acme-blue-1);
  }

  @media screen and (max-width: 520px) {
    width: 100%;
    max-width: 48%;
    margin: 4px 2px;
  }
}

.heatmapSection {
  margin-left: 500px;
  margin-right: 500px;
  @media screen and (max-width: 1900px) {
    margin-left: 400px;
    margin-right: 400px;
  }
  @media screen and (max-width: 1440px) {
    margin-left: 300px;
    margin-right: 300px;
  }
  @media screen and (max-width: 1200px) {
    margin-left: 150px;
    margin-right: 150px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media screen and (max-width: 700px) {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
